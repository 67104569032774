import { FrontendApi, Configuration } from "@ory/client"
import { basePath, oryJwtTokenTtl, oryJwtTokenizer } from "./config"


const handleAuth = async (setSession, setCookie, setLogoutUrl) => {
    const ory = new FrontendApi(
        new Configuration({
            basePath,
            baseOptions: {
                withCredentials: true,
            },
        }),
    );
    
    const session = await ory.toSession({
        tokenizeAs: oryJwtTokenizer,
    });

    // User has a session!
    setSession(session.data)

    let expires = new Date();
    expires.setTime(expires.getTime() + oryJwtTokenTtl * 60 * 1000);
    setCookie(
        'access_token',
        session.data.tokenized, 
        { 
            path: '/', 
            expires, 
            secure: true, 
            sameSite: 'strict' 
        }
    );

    const logout = await ory.createBrowserLogoutFlow();
    setLogoutUrl(logout.data.logout_url);
};

export { handleAuth }