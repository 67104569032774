import React from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';

const ResponsiveIFrameDialog = ({ isOpen, onClose, iframeSrc }) => {
  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose} 
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black bg-opacity-25" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <DialogPanel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <iframe
              title={iframeSrc}
              className="w-full h-[80vh] border-0" 
              src={iframeSrc}
            />
            <button
              type="button"
              className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={onClose}
            >
              Close
            </button>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default ResponsiveIFrameDialog