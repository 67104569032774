export const apiURL = process.env.REACT_APP_MOTORICA_API_URL

export const basePath = process.env.REACT_APP_MOTORICA_ORY_API

export const oryJwtTokenizer = "jwt_motorica_api_gw"
export const oryJwtTokenTtl = process.env.REACT_APP_MOTORICA_ORY_JWT_TTL_MINS

export const ENV_TYPE = process.env.REACT_APP_ENV_TYPE

export const backendAliveCheckInterval = 5000

export const BUILD_DATE = '20240918';
export const BUILD_GIT_HASH = '9561c51';
export const BUILD_GIT_BRANCH = 'develop';

export const PADDLE_CLIENT_TOKEN = process.env.REACT_APP_PADDLE_CLIENT_TOKEN
export const PADDLE_ENVIRONMENT = process.env.REACT_APP_PADDLE_ENVIRONMENT ?? "sandbox"
export const PADDLE_PRICE_IDS = process.env.REACT_APP_PADDLE_PRICE_IDS