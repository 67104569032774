export default function LoaderScreen({text}) {
    
    return (
        <div className="flex flex-col justify-center items-center align-middle min-h-screen bg-gray-900">
            <div role="status" className="flex flex-col justify-center items-center">
                <img className="invert" src="/assets/3-dots-fade.svg" width="100" height="100" alt="Loader" />
                <div className="text-gray-200 mt-2 text-center text-2xl w-full">{text}</div>
            </div>
        </div>
    )
}