import React, { useEffect, useState } from 'react';
import { initializePaddle } from '@paddle/paddle-js';
import { Radio, RadioGroup } from '@headlessui/react'
import Spinner from '../../components/Spinner';
import ResponsiveIFrameDialog from '../../components/ResponsiveIFrameDialog';
import { format, parseISO, formatISO } from 'date-fns';
import { getUserInfo } from "../../utils/backend";
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

import { PADDLE_CLIENT_TOKEN, PADDLE_ENVIRONMENT, PADDLE_PRICE_IDS, ENV_TYPE } from '../../utils/config';
const items = PADDLE_PRICE_IDS.split(',').map((p) => ({
  quantity: 1,
  priceId: p
}));

export default function Pricing({
  user,
  organization,
  session,
}) {
  const [loading, setLoading] = useState(false);
  const [paddle, setPaddle] = useState();
  const [pricePreview, setPricePreview] = useState();
  const [selected, setSelected] = useState();
  const [cancelDialog, setCancelDialog] = useState(false);
  const [updatePaymentDialog, setUpdatePaymentDialog] = useState(false);
  const [subscription, setSubscription] = useState();

  useEffect(() => {

    const waitForNewSubscription = async () => {
      // keep pulling from the backend until a the subscription has been updated
      const timeout = 10000;
      const interval = 1000;
      const startTime = Date.now();
      const updatedAt = parseISO(organization.subscription?.updated_at ?? formatISO(Date(0)));

      while (Date.now() - startTime < timeout) {
        const userdata = await getUserInfo(session.tokenized);
        console.log()
        if (userdata.organization?.subscription?.updated_at && parseISO(userdata.organization?.subscription?.updated_at) > updatedAt) {
          setSubscription(userdata.organization?.subscription);
          return;
        }

        await new Promise(resolve => setTimeout(resolve, interval));
      }
    };

    setSubscription(organization.subscription);
    initializePaddle({
      environment: PADDLE_ENVIRONMENT,
      token: PADDLE_CLIENT_TOKEN,
      debug: ENV_TYPE === "dev" || ENV_TYPE === undefined,
      eventCallback: function (data) {
        if (data.name === "checkout.completed") {
          setLoading(true);
          waitForNewSubscription()
            .catch((e) => console.error(e))
            .finally(() => setLoading(false));
        }
      }
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
        return paddleInstance.PricePreview({
          items: items,
          customerId: organization.subscription?.customer_id,
        })
      }
    }).then((result) => {
      setPricePreview(result.data);
      console.log(result);
    });
  }, [organization.subscription, session.tokenized, setSubscription]);

  const updateSubscription = (items) => {
    paddle.Checkout.open({
      items: items,
      customData: {
        user_id: user.id,
        organization_id: organization.id,
      },
      customer: {
        email: user.email,
      },
      settings: {
        allowLogout: false,
        theme: "dark",
      },
    });
  }

  if (pricePreview === undefined || loading) {
    return <Spinner />
  }

  const customerPriceIds = (subscription?.items ?? []).map((p) => p.price.id);

  return (
    <>
      <ResponsiveIFrameDialog isOpen={cancelDialog} onClose={() => setCancelDialog(false)} iframeSrc={subscription?.management_urls?.cancel} />
      <ResponsiveIFrameDialog isOpen={updatePaymentDialog} onClose={() => setUpdatePaymentDialog(false)} iframeSrc={subscription?.management_urls?.update_payment_method} />
      <div className="w-full px-4">
        <div className="mx-auto w-full max-w-4xl">
          {subscription?.scheduled_change?.action === "cancel" &&
            <div className="flex w-full items-center justify-between bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
            <div className="flex items-center">
              <ExclamationCircleIcon className="h-6 w-6 text-yellow-400 mr-3" />
              <div>
                <p className="font-semibold text-yellow-700">
                  Subscription Cancelled
                </p>
                <p className="text-sm text-yellow-600">
                  Your subscription plan change will be effective <span className="font-medium">{format(parseISO(subscription?.scheduled_change?.effective_at),'MMMM d, yyyy')}.</span>
                </p>
              </div>
            </div>
          </div>
          }
          {subscription?.next_billed_at &&
            <div className="flex w-full items-center justify-between mt-1">
              <span className="border-solid border-2 border-gray-500 bg-gray-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-900 dark:text-gray-300">
                Next Billing Date: {format(parseISO(subscription.next_billed_at), 'MMMM d, yyyy')}
              </span>
            </div>
          }
          {subscription?.management_urls?.update_payment_method &&
            <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded mb-4" onClick={() => {
              setUpdatePaymentDialog(true);
              setCancelDialog(false);
            }}>
              Update Payment Method
            </button>
          }
          <RadioGroup by="name" value={selected} onChange={setSelected} aria-label="Server size" className="space-y-2">
            {pricePreview.details.lineItems.map((item) => (
              <Radio
                key={item.price.name}
                value={item.price.id}
                className="group relative flex rounded-lg bg-white/5 py-4 px-5 text-white shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/10 mt-1"
              >
                <div className="flex w-full items-center justify-between">
                  <div className="text-lg">
                    <p className="font-semibold text-white capitalize">
                      {item.price.name}
                      {item.totals.total !== "0" &&
                        <span className="text-lg font-semibold text-gray-400 ml-3">{item.formattedTotals.subtotal}</span>
                      }
                      {item.totals.total !== "0" && item.price.billingCycle.frequency === 1 &&
                        <span className="text-sm font-semibold text-gray-400 ml-1 lowercase ">
                          / {item.price.billingCycle.interval}
                        </span>
                      }
                      {item.totals.total !== "0" && item.price.billingCycle.frequency > 1 &&
                        <span className="text-sm font-semibold text-gray-400 ml-1 lowercase ">
                          for {item.price.billingCycle.frequency} {item.price.billingCycle.interval}s
                        </span>
                      }
                    </p>
                    <span className="border-solid border-2 border-gray-500 bg-gray-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-900 dark:text-gray-300">{item.price.customData.credits} credits</span>
                  </div>
                  <div>
                    {customerPriceIds.includes(item.price.id) &&
                      <div>
                        <span className="bg-gray-100 text-gray-800 text-m font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-900 dark:text-gray-300 ml-3">Current</span>
                        <button
                          className="mt-2 px-4 py-2 bg-red-500 text-white rounded disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
      invalid:border-pink-500"
                          disabled={!!(subscription?.scheduled_change)}
                          onClick={() => {
                            setCancelDialog(true);
                            setUpdatePaymentDialog(false);
                          }}>
                          {!cancelDialog && "Cancel"}
                          {cancelDialog &&
                            "Cancelling..."
                          }
                        </button>
                      </div>
                    }
                    {!customerPriceIds.includes(item.price.id) && paddle &&
                      <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => updateSubscription([{ priceId: item.price.id }])}>
                        Choose
                      </button>
                    }
                  </div>
                </div>
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>

    </>

  );
}