import { apiURL, backendAliveCheckInterval } from './config'

const fetchWithRetries = async (url, options, retryCount = 0) => {
    // split out the maxRetries option from the remaining
    // options (with a default of 3 retries)
    const { maxRetries = 5, ...remainingOptions } = options;
    try {
        const resp = await fetch(url, remainingOptions);
        return resp;
    } catch (error) {
        console.error(error);
        // if the retryCount has not been exceeded, call again
        if (retryCount < maxRetries) {
            // wait 2 seconds before retrying
            await new Promise((resolve) => setTimeout(resolve, backendAliveCheckInterval));

            // retry the request
            return fetchWithRetries(url, options, retryCount + 1);
        }
        // max retries exceeded
        throw error;
    }
}

const checkBackendAlive = async (bearerToken) => {

    const res = await fetchWithRetries(
        `${apiURL}checkAlive`,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + bearerToken,
                'Content-Type': 'application/json',
            },
        },
        3
    )

    if (res.status === 200) {
        return true
    }

    return false
}

const getUserInfo = async (bearerToken, urlparams) => {
    const res = await fetchWithRetries(
        `${apiURL}users/whoAmI?` + new URLSearchParams(urlparams ?? {}),
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + bearerToken,
                'Content-Type': 'application/json',
            },
        },
    )

    if (res.status === 200) {
        return await res.json()
    }

    throw new Error(`[${res.status}] ${await res.text()}`);

}

const storeUserData = async (bearerToken, userData) => {

    const res = await fetchWithRetries(
        `${apiURL}users`,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + bearerToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        },
        3
    )

    if (res.status === 200) {
        await new Promise((resolve) => setTimeout(resolve, 2000))
    }

    if (res.status !== 200) {
        alert("Something went wrong. Please try again.")
    }
}

export { checkBackendAlive, storeUserData, getUserInfo}