import React, { useEffect, useState } from 'react';
import { Tab, TabGroup, TabPanels, TabPanel, TabList } from '@headlessui/react';

import { XMarkIcon, UserIcon, CreditCardIcon, CurrencyDollarIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from "../../utils/backend";
import Pricing from "./billing";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const tabNames = ['settings', 'members', 'billing'];

const SettingsPage = ({
  user,
  organization,
  session,
  setOrganization,
}) => {
  const [transactions, setTransactions] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const navigate = useNavigate();

  useEffect(() => {
    // Parse the URL hash on component mount
    const hash = window.location.hash.replace('#', '')
    const index = tabNames.indexOf(hash)
    if (index !== -1) {
      setSelectedTabIndex(index)
    }
  }, []);

  useEffect(() => {
    if (session)
      (async () => {
        const userdata = await getUserInfo(session.tokenized, { include: ['transactions'] });
        setOrganization(userdata?.organization ?? {});
        setTransactions(userdata?.transactions ?? []);
      })();
  }, [setTransactions, setOrganization, session]);

  if (!user || !session) return <></>

  const onTabChange = (index) => {
    setSelectedTabIndex(index);
    window.location.hash = tabNames[index];
  }

  return (
    <div className="p-6 bg-black text-gray-200">
      <div className="flex items-center mb-6">
        <div className="w-12 h-12 bg-gray-600 rounded-md flex items-center justify-center">
          <p className="text-sm">{user.firstName.substring(0, 2)}</p>
        </div>
        <span className="ml-4 text-xl">{user.firstName} {user.lastName}</span>
        <span className="ml-auto">
          <XMarkIcon className="block h-6 w-6 cursor-pointer" aria-hidden="true" onClick={() => navigate("/")} />
        </span>
      </div>
      <TabGroup selectedIndex={selectedTabIndex} onChange={onTabChange}>
        <TabList className="flex space-x-4 mb-6">
          <Tab
            className={({ selected }) =>
              classNames(
                'px-3 py-2 text-sm font-medium',
                selected ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
              )
            }
          >
            Settings
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'px-3 py-2 text-sm font-medium',
                selected ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
              )
            }
          >
            Members
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'px-3 py-2 text-sm font-medium',
                selected ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
              )
            }
          >
            Plans & Billing
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel className="divide-y divide-gray-700">
            <div className="mb-10">
              <h1 className="text-2xl mb-4">Workspace</h1>
              <div className="text-gray-500">Name</div>
              <div className="text-lg">{organization.name}</div>
            </div>

            {organization.subscription &&
              <div className="mb-10">
                <div className="text-gray-500">Subscription</div>
                <div className="text-lg capitalize">{(organization.subscription.items ?? []).map((i) => i.price.name).join('+')}</div>
                <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => setSelectedTabIndex(2)}>Upgrade</button>
              </div>
            }
            <div className="mb-10">
              <div className="text-gray-500">Credits</div>
              <div className="text-lg">{(organization?.creditsSubscriptionBalance ?? 0) + (organization?.creditsPurchaseBalance ?? 0)}</div>
              <ul>
                <li key="0" className="p-4 hover:bg-gray-900 transition duration-150 ease-in-out">
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center text-white">
                    <div className="flex items-center space-x-3">
                      <span className="text-sm">{organization?.creditsSubscriptionBalance ?? 0} from Subscription Plan</span>
                    </div>
                  </div>
                </li>
                <li key="1" className="p-4 hover:bg-gray-900 transition duration-150 ease-in-out">
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center text-white">
                    <div className="flex items-center space-x-3">
                      <span className="text-sm">{organization?.creditsPurchaseBalance ?? 0} Purchased Credits</span>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="text-sm text-gray-500">Credits are shared in this workspace.</div>
              <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => setSelectedTabIndex(2)}>Get credits</button>
            </div>
            <div className="mb-10">
              <div className="text-gray-500">Workspace members</div>
              <div className="text-lg">1 seat in use</div>
              <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => setSelectedTabIndex(1)}>Manage members</button>
            </div>
            <div>
              <div className="text-gray-500">Transactions (last 30 days)</div>

              {transactions.length === 0 &&
                <div className="text-lg">No transactions available</div>
              }

              <ul>
                {transactions.map((item, index) => (
                  <li key={index} className="p-4 hover:bg-gray-900 transition duration-150 ease-in-out">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center text-white">
                      <div className="flex items-center space-x-3">
                        <CalendarIcon className="h-5 w-5 text-indigo-400" />
                        <span className="text-sm">{new Date(item.createdAt).toLocaleString()}</span>
                      </div>
                      <div className="flex items-center space-x-3">
                        <CurrencyDollarIcon className="h-5 w-5 text-green-400" />
                        <span className="text-sm font-medium">{item.credits} credits</span>
                      </div>
                      <div className="flex items-center space-x-3">
                        <CreditCardIcon className="h-5 w-5 text-yellow-400" />
                        <span className="text-sm">{item.operation}</span>
                      </div>
                      <div className="flex items-center space-x-3">
                        <UserIcon className="h-5 w-5 text-blue-400" />
                        <span className="text-sm truncate">{item.user}</span>
                      </div>
                    </div>
                    <p className="mt-2 text-sm text-gray-400">{item.description}</p>
                  </li>
                ))}
              </ul>

            </div>
          </TabPanel>
          <TabPanel>
            <h1 className="text-2xl mb-4">Members</h1>
            <div className="text-lg">{user.email}</div>
          </TabPanel>
          <TabPanel>
            <Pricing 
              user={user}
              organization={organization}
              session={session}
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default SettingsPage;
